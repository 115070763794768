import React from "react";
import Aditya from '../assets/img/testimonials/aditya-saxena-tP7CtZ_2QK8-unsplash.jpg'
import Davisuko from '../assets/img/testimonials/davisuko-2FdIvx7sy3U-unsplash.jpg'
import Robert from '../assets/img/testimonials/robert-tudor-BydNJ_dOVYg-unsplash.jpg'

function Testimonials() {
    return (
        <section className="dark">
            <section className="testimonials no-bg section-padding" style={{ background: 'black' }}>
                <div className="text-bg">Testimonials</div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 offset-lg-1 positive-r">
                            <div className="slider-for wow fadeInUp" data-wow-delay=".3s">
                                <div className="info">
                                    <div className="author">
                                        <img src={Robert} alt="" />
                                    </div>
                                    <h6 style={{ color: 'white' }}>Alex Martin <span style={{ color: 'white' }}>Envato Customer</span> </h6>
                                </div>
                                <div className="info">
                                    <div className="author">
                                        <img src={Aditya} alt="" />
                                    </div>
                                    <h6 style={{ color: 'white' }}>Alex Martin <span style={{ color: 'white' }}>Envato Customer</span> </h6>
                                </div>
                                <div className="info">
                                    <div className="author">
                                        <img src={Davisuko} alt="" />
                                    </div>
                                    <h6 style={{ color: 'white' }}>Alex Martin <span style={{ color: 'white' }}>Envato Customer</span> </h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 quote-text">
                            <div className="slider-nav wow fadeInUp" data-wow-delay=".3s">
                                <div className="item">
                                    <div className="cont">
                                        <p style={{ color: 'white' }}>" Nulla metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat
                                            condimentum velit className aptent taciti sociosqu ad litora torquent per
                                            conubia
                                            nostra. "
                                        </p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="cont">
                                        <p style={{ color: 'white' }}>" Nulla metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat
                                            condimentum velit className aptent taciti sociosqu ad litora torquent per
                                            conubia
                                            nostra. "
                                        </p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="cont">
                                        <p style={{ color: 'white' }}>" Nulla metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat
                                            condimentum velit className aptent taciti sociosqu ad litora torquent per
                                            conubia
                                            nostra. "
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <span className="quote-icon wow fadeInUp" data-wow-delay=".5s"><img src="img/icons/quote.svg"
                                alt="" /></span>
                        </div>
                    </div>
                </div>
            </section>

        </section>
    )
}

export default Testimonials;
import React from 'react';
import Img from 'react-image';
import FlagMockup1 from '../assets/img/slider_wrapper/FlagMockup1_optimized.jpeg';
import FlagMockup2 from '../assets/img/slider_wrapper/FlagMockup2_optimized.jpeg';
import Charles from '../assets/img/about_agency/charlesdeluvio-qnQc2iMpacU-unsplash.jpg'
import Tomas from '../assets/img/about_agency/tomas-malik-CMkJ-oT4lsQ-unsplash.jpg'
import Annie from '../assets/img/case_study/annie-spratt-txja6Za7lrg-unsplash.jpg'
import Paris from '../assets/img/case_study/paris-bilal-SOVqqewKT8k-unsplash.jpg'
import Pascal from '../assets/img/case_study/pascal-bullan-ei1L6HxFvMM-unsplash.jpg'
import Pawel from '../assets/img/case_study/pawel-czerwinski-z6oQ0OqSfuQ-unsplash.jpg'
import Tirzan from '../assets/img/case_study/tirza-van-dijk-cNGUw-CEsp0-unsplash.jpg'
import Catia from '../assets/img/creative_works/catia-dombaxe-cAqCwoF-_4c-unsplash.jpg'
import Joseph from '../assets/img/creative_works/joseph-prospere-Hr4x10oTpwg-unsplash.jpg'
import Luke from '../assets/img/creative_works/luke-miller-wZ-9d29Fweo-unsplash.jpg'
import Maeva from '../assets/img/creative_works/maeva-vigier-aGYnZK8v8KQ-unsplash.jpg'
import Sehoon from '../assets/img/creative_works/sehoon-ye-uBpCKXiKoF0-unsplash.jpg'
import Sergey from '../assets/img/creative_works/sergey-leont-ev-HCOEKP90YLw-unsplash.jpg'
import Tomas2 from '../assets/img/creative_works/tomas-malik-jzFyVKqzyaQ-unsplash.jpg'
import Tomas3 from '../assets/img/creative_works/tomas-malik-sjGuXYvjrRk-unsplash.jpg'
import Aditya from '../assets/img/testimonials/aditya-saxena-tP7CtZ_2QK8-unsplash.jpg'
import Davisuko from '../assets/img/testimonials/davisuko-2FdIvx7sy3U-unsplash.jpg'
import Robert from '../assets/img/testimonials/robert-tudor-BydNJ_dOVYg-unsplash.jpg'



const ImageComponent = () => {
    <div>
        <img src={FlagMockup1} alt="Imagem Slider About Agency" />

        <img src={FlagMockup2} alt="Imagem Slider About Agency" />

        <img src={Charles} alt="Imagem About Agency" />

        <img src={Tomas} alt="Imagem About Agency" />

        <img src={Annie} alt="Imagem Case Study" />

        <img src={Paris} alt="Imagem Case Study" />

        <img src={Pascal} alt="Imagem Case Study" />

        <img src={Pawel} alt="Imagem Case Study" />

        <img src={Tirzan} alt="Imagem Case Study" />

        <img src={Catia} alt="Imagem Case Study" />
        
        <img src={Joseph} alt="Imagem Case Study" />

        <img src={Tirzan} alt="Imagem Case Study" />

        <img src={Luke} alt="Imagem Case Study" />

        <img src={Maeva} alt="Imagem Case Study" />

        <img src={Sehoon} alt="Imagem Case Study" />

        <img src={Sergey} alt="Imagem Case Study" />

        <img src={Tomas2} alt="Imagem Case Study" />

        <img src={Tomas3} alt="Imagem Case Study" />

        <img src={Aditya} alt="Imagem Case Study" />

        <img src={Davisuko} alt="Imagem Case Study" />

        <img src={Robert} alt="Imagem Case Study" />



        
    </div>

};

export default ImageComponent;

//import './App.css';
//import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import i18n from 'i18next';
import './assets/css/plugins.css'
import './assets/css/style.css'
import './assets/css/style.css.map'
import './Scripts';


import ImageComponent from './components/Cache-Image';
import Logo from './components/Logo';
import SliderWrapper from './components/Slider-Wrapper';
import SliderAboutAgency from './components/Slider-About-Agency';
import Works from './components/Works';
import About from './components/About';
import MinimalArea from './components/Minimal-Area';
import VideoWrapper from './components/Video-Wrapper';
import Blog from './components/Blog';
import Testimonials from './components/Testimonials';
import ClientBrands from './components/Clients-Brands';
import CallToAction from './components/Call-To-Action';
import FooterSm from './components/Footer-Sm';
import StartCursor from './components/Start-Cursor';
import ProgressScrollButton from './components/Progress-Scroll-Button';
import { useTranslation } from 'react-i18next';


const App = () => {
  const { t } = useTranslation;

  return (
    <BrowserRouter>
      <div className="dark">
        <ImageComponent/>
        <Logo />
        <SliderWrapper />
        <SliderAboutAgency />
        <Works />
        <About />
        <MinimalArea />
        <VideoWrapper />
        <Testimonials />
        <Blog />
        <ClientBrands />
        <CallToAction />
        <FooterSm />
        <StartCursor />
        <ProgressScrollButton />
      </div>
    </BrowserRouter>
  );
}

export default App;

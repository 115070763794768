import React from "react";
import video from "../video/exemplo.mp4";

function VideoWrapper() {
    return (
        <div className="containerFirst">
            <video autoPlay muted playsInline loop loading="lazy" className='videoBackground'>
                <source src={video} type="video/mp4" />
            </video>
        </div>
    );
}

export default VideoWrapper;
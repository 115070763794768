import React from "react";
import i18n from '../assets/js/i18n'
import { useTranslation } from "react-i18next";

function ClientBrands() {

    const { t } = useTranslation();

    return (
        <section className="dark">
            <div className="wrapper">
                <section className="clients section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10">
                                <div className="intro mb-80">
                                    <h6 className="sub-title wow" data-splitting>{t('Clients-Brands.title')}</h6>
                                    <h2 className="extra-title wow" data-splitting style={{ color: 'white' }}>{t('Clients-Brands.subtitle')}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row bord">
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                                    <div className="img">
                                        <img src="img/clients/brands/01.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".6s">
                                    <div className="img">
                                        <img src="img/clients/brands/02.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".8s">
                                    <div className="img">
                                        <img src="img/clients/brands/03.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                                    <div className="img">
                                        <img src="img/clients/brands/04.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".4s">
                                    <div className="img">
                                        <img src="img/clients/brands/05.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".7s">
                                    <div className="img">
                                        <img src="img/clients/brands/06.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".5s">
                                    <div className="img">
                                        <img src="img/clients/brands/07.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 brands">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                                    <div className="img">
                                        <img src="img/clients/brands/08.png" alt="" />
                                    </div>
                                    <a href="#0" className="link" data-splitting style={{ color: 'white' }}>www.wavo.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>

    )
}


export default ClientBrands;
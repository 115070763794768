import React from "react";
import { useTranslation } from "react-i18next";

function About() {

    const { t } = useTranslation();

    return (
        <section className="dark">
            <div className="wrapper">
                <section className="about blc section-padding" data-overlay-dark="0">
                    <div className="container">
                        <div className="row mb-80">
                            <div className="col-lg-7">
                                <div className="intro">
                                    <h6 className="sub-title wow" data-splitting>{t('About.title')}</h6>
                                    <h2 className="extra-title wow" data-splitting>{t('About.subtitle')}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 items">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                                    <span className="icon">
                                        <i className="ion-ios-monitor"></i>
                                    </span>
                                    <h5>{t('About.h5')}</h5>
                                    <p>{t('About.p1')}</p>
                                    <a href="#0" className="more-stroke"><span></span></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 items active">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                                    <span className="icon">
                                        <i className="ion-ios-bolt-outline"></i>
                                    </span>
                                    <h5>{t('About.h5(2)')}</h5>
                                    <p>{t('About.p2')}</p>
                                    <a href="#0" className="more-stroke"><span></span></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 items">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                                    <span className="icon">
                                        <i className="ion-cube"></i>
                                    </span>
                                    <h5>{t('About.h5(3)')}</h5>
                                    <p>{t('About.p3')}</p>
                                    <a href="#0" className="more-stroke"><span></span></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 items">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                                    <span className="icon">
                                        <i className="ion-ios-color-wand"></i>
                                    </span>
                                    <h5>{t('About.h5(4)')}</h5>
                                    <p>{t('About.p4')}</p>
                                    <a href="#0" className="more-stroke"><span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

export default About;
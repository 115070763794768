import $ from "jquery";
import Splitting from "splitting";
import simpleParallax from "splitting";
import * as animateWow from "wowjs";
import Swiper from "swiper";
var didScroll;
$(function () {
    "use strict";
    var e = $(window);
    function i() {
        window.scrollTo(0, 0);
    }
    e.on("scroll", function () {
        var i = e.scrollTop(),
            s = $(".topnav");
        i > 300 ? s.addClass("nav-scroll") : s.removeClass("nav-scroll");
    });
    var s = !1,
        t = $(".topnav.dark"),
        a = $(".topnav.dark .logo img");
    $(".topnav .menu-icon").on("click", function () {
        (s = !s),
            $(".hamenu").toggleClass("open"),
            s
                ? ($(".hamenu").animate({ left: 0 }), $(".topnav .menu-icon .text").addClass("open"), t.addClass("navlit"), a.attr("src", "img/logo-light.png"), window.addEventListener("scroll", i))
                : ($(".hamenu").delay(300).animate({ left: "-100%" }), $(".topnav .menu-icon .text").removeClass("open"), t.removeClass("navlit"), a.attr("src", "img/logo-dark.png"), window.removeEventListener("scroll", i));
    }),
        $(".hamenu .menu-links .main-menu > li").on("mouseenter", function () {
            $(this).css("opacity", "1").siblings().css("opacity", ".5");
        }),
        $(".hamenu .menu-links .main-menu > li").on("mouseleave", function () {
            $(".hamenu .menu-links .main-menu > li").css("opacity", "1");
        }),
        $(".main-menu > li .dmenu").on("click", function () {
            $(".main-menu").addClass("gosub"), $(this).parent().parent().find(".sub-menu").addClass("sub-open");
        }),
        $(".main-menu .sub-menu li .sub-link.back").on("click", function () {
            $(".main-menu").removeClass("gosub"), $(".main-menu .sub-menu").removeClass("sub-open");
        }),
        new Swiper(".slider .parallax-slider", {
            speed: 1e3,
            autoplay: !0,
            parallax: !0,
            loop: !0,
            pagination: { el: ".slider .parallax-slider .swiper-pagination", clickable: !0 },
            on: {
                init: function () {
                    for (var e = 0; e < this.slides.length; e++)
                        $(this.slides[e])
                            .find(".bg-img")
                            .attr({ "data-swiper-parallax": 0.75 * this.width });
                },
                resize: function () {
                    this.update();
                },
            },
            pagination: { el: ".slider .parallax-slider .swiper-pagination", type: "fraction" },
            navigation: { nextEl: ".slider .parallax-slider .next-ctrl", prevEl: ".slider .parallax-slider .prev-ctrl" },
        }),
        new Swiper(".slide-full .parallax-slider", {
            speed: 1e3,
            mousewheel: !0,
            parallax: !0,
            loop: !0,
            pagination: { el: ".slide-full .parallax-slider .swiper-pagination", clickable: !0 },
            on: {
                init: function () {
                    for (var e = 0; e < this.slides.length; e++)
                        $(this.slides[e])
                            .find(".bg-img")
                            .attr({ "data-swiper-parallax": 0.75 * this.width });
                },
                resize: function () {
                    this.update();
                },
            },
            pagination: { el: ".slide-full .parallax-slider .swiper-pagination", type: "fraction" },
            navigation: { nextEl: ".slide-full .parallax-slider .next-ctrl", prevEl: ".slide-full .parallax-slider .prev-ctrl" },
        });
    new Swiper(".metro .swiper-container", {
        slidesPerView: 2,
        spaceBetween: 0,
        speed: 1e3,
        loop: !0,
        centeredSlides: !0,
        breakpoints: { 320: { slidesPerView: 1, spaceBetween: 0 }, 480: { slidesPerView: 1, spaceBetween: 0 }, 640: { slidesPerView: 2, spaceBetween: 0 } },
        pagination: { el: ".metro .swiper-pagination", type: "progressbar" },
        navigation: { nextEl: ".metro .next-ctrl", prevEl: ".metro .prev-ctrl" },
    }),
        new Swiper(".colspace .swiper-container", {
            spaceBetween: 0,
            speed: 1e3,
            loop: !0,
            centeredSlides: !0,
            breakpoints: { 320: { slidesPerView: 1, spaceBetween: 0 }, 480: { slidesPerView: 1, spaceBetween: 0 }, 640: { slidesPerView: 2, spaceBetween: 0 }, 991: { slidesPerView: 3, spaceBetween: 0 } },
            pagination: { el: ".colspace .swiper-pagination", type: "progressbar" },
            navigation: { nextEl: ".colspace .next-ctrl", prevEl: ".colspace .prev-ctrl" },
        }),
        new Swiper(".mcolumn .swiper-container", {
            slidesPerView: 3,
            spaceBetween: 0,
            speed: 1e3,
            loop: !0,
            centeredSlides: !0,
            breakpoints: { 320: { slidesPerView: 1, spaceBetween: 0 }, 480: { slidesPerView: 2, spaceBetween: 0 }, 640: { slidesPerView: 2, spaceBetween: 0 }, 991: { slidesPerView: 3, spaceBetween: 0 } },
            navigation: { nextEl: ".mcolumn .next-ctrl", prevEl: ".mcolumn .prev-ctrl" },
        }),
        new Swiper(".slider-scroll .swiper-container", {
            slidesPerView: 2,
            spaceBetween: 30,
            mousewheel: !0,
            centeredSlides: !0,
            speed: 1e3,
            loop: !0,
            breakpoints: { 320: { slidesPerView: 1 }, 480: { slidesPerView: 1 }, 640: { slidesPerView: 2 }, 991: { slidesPerView: 2 } },
            navigation: { nextEl: ".slider-scroll .next-ctrl", prevEl: ".slider-scroll .prev-ctrl" },
        }),
        new Swiper(".blog .swiper-img", {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 800,
            loop: !0,
            effect: "fade",
            pagination: { el: ".blog .controls .swiper-pagination", type: "fraction" },
            navigation: { nextEl: ".next-ctrl", prevEl: ".prev-ctrl" },
        }),
        new Swiper(".blog .swiper-content", {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 800,
            loop: !0,
            pagination: { el: ".blog .controls .swiper-pagination", type: "fraction" },
            navigation: { nextEl: ".blog .controls .next-ctrl", prevEl: ".blog .controls .prev-ctrl" },
        });
    $(".about .items").on("mouseenter", function () {
        $(this).addClass("active").siblings().removeClass("active");
    }),
        $(".social .icon").on("click", function () {
            $(this).parent().toggleClass("active");
        }),
        $(".team .item .img").on("mouseenter", function () {
            $(".team .item .img").addClass("filter"), $(this).removeClass("filter");
        }),
        $(".team .item .img").on("mouseleave", function () {
            $(".team .item .img").removeClass("filter");
        }),
        $(".bg-img, section").each(function (e) {
            $(this).attr("data-background") && $(this).css("background-image", "url(" + $(this).data("background") + ")");
        }),
        $(".testimonials .slider-for").slick({ slidesToShow: 1, slidesToScroll: 1, arrows: !0, prevArrow: ".prev", nextArrow: ".next", dots: !1, autoplay: !0, fade: !0, asNavFor: ".slider-nav" }),
        $(".testimonials .slider-nav").slick({ slidesToShow: 1, asNavFor: ".slider-for", arrows: !1, dots: !1, focusOnSelect: !0 });
    var n = !0;
    e.on("scroll", function () {
        e.scrollTop() >= $(".about").offset().top - 75 &&
            n &&
            ($(".about .svg-icon").each(function () {
                $(".svg-icon").drawsvg({ duration: 4e3 }).drawsvg("animate");
            }),
            (n = !1));
    }),
        $("a.vid").YouTubePopUp(),
        $(".parallaxie").parallaxie({ speed: 0.2, size: "cover" }),
        $(".justified-gallery").justifiedGallery({ rowHeight: 400, lastRow: "nojustify", margins: 15 });
    var l = $(".blog-grid .post-img .img").outerHeight() + 120;
    $(".blog-grid .bg-pattern").css("height", l);
}),
    $(window).on("load", function () {
        Splitting();
        var e = document.getElementsByClassName("thumparallax");
        new simpleParallax(e, { delay: 1 });
        var i = document.getElementsByClassName("thumparallax-down");
        new simpleParallax(i, { orientation: "down", delay: 1 }),
            $("#contact-form").on("submit", function (e) {
                if (!e.isDefaultPrevented()) {
                    return (
                        $.ajax({
                            type: "POST",
                            url: "contact.php",
                            data: $(this).serialize(),
                            success: function (e) {
                                var i = "alert-" + e.type,
                                    s = e.message,
                                    t = '<div class="alert ' + i + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + s + "</div>";
                                i && s && ($("#contact-form").find(".messages").html(t), $("#contact-form")[0].reset());
                            },
                        }),
                        !1
                    );
                }
            });
    });
var lastScrollTop = 0,
    delta = 5,
    navbarHeight = $("#navi").outerHeight();
function hasScrolled() {
    var e = $(this).scrollTop();
    Math.abs(lastScrollTop - e) <= delta || (e > lastScrollTop && e > navbarHeight ? $("#navi").css("top", "-100px") : e + $(window).height() < $(document).height() && $("#navi").css("top", "0"), (lastScrollTop = e));
}
$(window).on("scroll", function (e) {
    didScroll = !0;
}),
    setInterval(function () {
        didScroll && (hasScrolled(), (didScroll = !1));
    }, 250);
var wow = new animateWow.WOW({ animateClass: "animated", offset: 100, mobile: !0, live: !0, callback: function (e) {} });
wow.init(),
    $(document).ready(function () {
        "use strict";
        var e = document.querySelector(".progress-wrap path"),
            i = e.getTotalLength();
        (e.style.transition = e.style.WebkitTransition = "none"),
            (e.style.strokeDasharray = i + " " + i),
            (e.style.strokeDashoffset = i),
            e.getBoundingClientRect(),
            (e.style.transition = e.style.WebkitTransition = "stroke-dashoffset 10ms linear");
        var s = function () {
            var s = $(window).scrollTop(),
                t = $(document).height() - $(window).height(),
                a = i - (s * i) / t;
            e.style.strokeDashoffset = a;
        };
        s(), $(window).scroll(s);
        jQuery(window).on("scroll", function () {
            jQuery(this).scrollTop() > 150 ? jQuery(".progress-wrap").addClass("active-progress") : jQuery(".progress-wrap").removeClass("active-progress");
        }),
            jQuery(".progress-wrap").on("click", function (e) {
                return e.preventDefault(), jQuery("html, body").animate({ scrollTop: 0 }, 550), !1;
            });
    }),
    $(window).scroll(function () {
        var e = $(this).scrollTop();
        $(".slider .caption").css({ transform: "translate3d(0, " + -0.2 * e + "px, 0)", opacity: 1 - e / 600 });
    });

import React from "react";

function FooterSm() {
    return (
        <section className="dark">
            <div className="wrapper">
                <footer className="footer-sm">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="left">
                                    <p>Todos os direitos.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right">
                                    <p>2024, Flag Lab. Digital que inova.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </section>
    )
}

export default FooterSm;
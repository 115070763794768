import React, { useEffect, useState } from 'react';
import BRAFlag from './Flags/BRAFlag.jsx';
import USAFlag from './Flags/USAFlag.jsx';
import Logo from "../assets/img/logo/logo.png"

function Navigation() {

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    }, []);     

  const scale = scrollY > 100 ? 0.7 : 1;

    return (
        <section className="dark">
            <nav className="nav-item-effects">
                <div id="navi" className="topnav">
                    <div className="container-fluid-test">
                        <div className="logo" style={{ transform: `scale(${scale})` }}>
                            <a href="#0"><img src={Logo} alt="" /></a>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="button-flags">
                <BRAFlag className="buttonBRA"></ BRAFlag>
                <USAFlag className="buttonUSA"></USAFlag>
            </div>
        </section>
    )
}

export default Navigation;
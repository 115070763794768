import React from "react";
import i18n from '../assets/js/i18n'
import { useTranslation } from "react-i18next";
import Pascal from '../assets/img/case_study/pascal-bullan-ei1L6HxFvMM-unsplash.jpg'
import Pawel from '../assets/img/case_study/pawel-czerwinski-z6oQ0OqSfuQ-unsplash.jpg'
import Annie from '../assets/img/case_study/annie-spratt-txja6Za7lrg-unsplash.jpg'



function Blog() {

    const { t } = useTranslation();

    return (
        <section className="dark">
            <div className="wrapper">
                <section className="blog-grid section-padding pb-0">
                    <div className="stories bg-img no-cover bg-pattern" data-background="img/pattern.svg"></div>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4">
                                <div className="item wow fadeInUp md-mb50" data-wow-delay=".3s">
                                    <div className="post-img">
                                        <div className="img">
                                            <img src={Annie} alt="" />
                                        </div>
                                    </div>
                                    <div className="cont">
                                        <div className="info">
                                            <a href="#0">{t('Blog.name')}</a>
                                            <a href="#0">{t('Blog.date')}</a>
                                        </div>

                                        <h5><a href="#0" style={{ color: 'white' }}>{t('Blog.h5')}</a></h5>

                                        <a href="blog-details-dark.html" className="more">
                                            <span>{t('Blog.span')} <i className="icofont-caret-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="item wow fadeInUp md-mb50" data-wow-delay=".1s">
                                    <div className="post-img">
                                        <div className="img">
                                            <img src={Pascal} alt="" />
                                        </div>
                                    </div>
                                    <div className="cont">
                                        <div className="info">
                                            <a href="#0">{t('Blog.name')}</a>
                                            <a href="#0">{t('Blog.date')}</a>
                                        </div>

                                        <h5><a href="#0" style={{ color: 'white' }}>{t('Blog.h5(2)')}</a></h5>

                                        <a href="blog-details-dark.html" className="more">
                                            <span>{t('Blog.span')} <i className="icofont-caret-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="item wow fadeInUp" data-wow-delay=".5s">
                                    <div className="post-img">
                                        <div className="img">
                                            <img src={Pawel} alt="" />
                                        </div>
                                    </div>
                                    <div className="cont">
                                        <div className="info">
                                            <a href="#0">{t('Blog.name')}</a>
                                            <a href="#0">{t('Blog.date')}</a>
                                        </div>

                                        <h5><a href="#0" style={{ color: 'white' }}>{t('Blog.h5')}</a></h5>

                                        <a href="blog-details-dark.html" className="more">
                                            <span>{t('Blog.span')}<i className="icofont-caret-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

export default Blog;
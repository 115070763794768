import React from "react";
import i18n from '../assets/js/i18n'
import { useTranslation } from "react-i18next";
import Catia from '../assets/img/creative_works/catia-dombaxe-cAqCwoF-_4c-unsplash.jpg'
import Joseph from '../assets/img/creative_works/joseph-prospere-Hr4x10oTpwg-unsplash.jpg'
import Luke from '../assets/img/creative_works/luke-miller-wZ-9d29Fweo-unsplash.jpg'
import Maeva from '../assets/img/creative_works/maeva-vigier-aGYnZK8v8KQ-unsplash.jpg'
import Sehoon from '../assets/img/creative_works/sehoon-ye-uBpCKXiKoF0-unsplash.jpg'
import Sergey from '../assets/img/creative_works/sergey-leont-ev-HCOEKP90YLw-unsplash.jpg'
import Tomas from '../assets/img/creative_works/tomas-malik-jzFyVKqzyaQ-unsplash.jpg'
import Tomas2 from '../assets/img/creative_works/tomas-malik-sjGuXYvjrRk-unsplash.jpg'

function Works() {

    const { t } = useTranslation();

    return (
        <section className="dark">
            <div className="wrapper">
                    <section className="work-carousel mcolumn">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-head">
                                        <h6 className="wow" data-splitting style={{color:'white'}}>{t('Works.title')}</h6>
                                        <h3 className="wow" data-splitting style={{color:'white'}}>{t('Works.subtitle')}.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 no-padding">
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="300"></div><img
                                                                src={Catia} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="500"></div><img
                                                                src={Joseph} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4(2)')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="700"></div><img
                                                                src={Luke} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4(3)')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="400"></div><img
                                                                src={Maeva} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="200"></div><img
                                                                src={Sehoon} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="500"></div><img
                                                                src={Sergey} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4(2)')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="300"></div><img
                                                                src={Tomas} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4(3)')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="content">
                                                    <div className="img">
                                                        <span className="imgio">
                                                            <div className="wow cimgio" data-delay="200"></div><img
                                                                src={Tomas2} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>{t('Works.h6')}</h6>
                                                        <h4>{t('Works.h4')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-button-next swiper-nav-ctrl next-ctrl">
                                            <i className="ion-ios-arrow-right"></i>
                                        </div>
                                        <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl">
                                            <i className="ion-ios-arrow-left"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
        </section>
    )
}

export default Works;
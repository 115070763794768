import React from "react";
import i18n from '../assets/js/i18n'
import { useTranslation } from "react-i18next";
import Tirzan from '../assets/img/case_study/tirza-van-dijk-cNGUw-CEsp0-unsplash.jpg'

function MinimalArea() {

    const { t } = useTranslation();

    return (
        <section className="dark">
            <div className="wrapper">
                <section className="min-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="img">
                                    <img className="thumparallax-down" src={Tirzan} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 valign">
                                <div className="content">
                                    <h6 className="sub-title wow" data-splitting>{t('Minimal-Area.title')}</h6>
                                    <h4 className="wow" data-splitting style={{ color: 'white' }}>{t('Minimal-Area.subtitle')}</h4>
                                    <p className="wow txt" data-splitting>{t('Minimal-Area.p')}</p>
                                    <ul>
                                        <li className="wow fadeInUp" data-wow-delay=".2s" style={{ color: 'white' }}>{t('Minimal-Area.li')}</li>
                                        <li className="wow fadeInUp" data-wow-delay=".4s" style={{ color: 'white' }}>{t('Minimal-Area.li2')}</li>
                                    </ul>
                                    <a href="about-dark.html" className="btn-curve mt-40 wow fadeInUp"
                                        data-wow-delay=".8s"><span style={{ color: 'white' }}>{t('Minimal-Area.a')}</span></a><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}


export default MinimalArea;
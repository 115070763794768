import React from "react";

function StartCursor() {
    return (
        <section className="dark">
            <div className="cursor"></div>
            <div className="cursor2"></div>
        </section>
    )
}


export default StartCursor;
import React from "react";
import FlagMockup1 from '../assets/img/slider_wrapper/FlagMockup1_optimized.jpeg';
import FlagMockup2 from '../assets/img/slider_wrapper/FlagMockup2_optimized.jpeg';

function SliderWrapper() {
    return (
        <section className="dark">
            <div className="wrapper">
                <section className="slider fixed-slider center-slider slide-controls">
                    <div className="swiper-container parallax-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="bg-img valign" style={{ backgroundImage: `url(${FlagMockup1})`}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-10 offset-lg-1">
                                                <div className="caption">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="bg-img valign" style={{ backgroundImage: `url(${FlagMockup2})`}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-10 offset-lg-1">
                                                <div className="caption">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-button-next swiper-nav-ctrl next-ctrl">
                            <i className="fas fa-caret-right"></i>
                        </div>
                        <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl">
                            <i className="fas fa-caret-left"></i>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default SliderWrapper;
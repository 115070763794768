import React from "react";
import i18n from "../assets/js/i18n"
import Agency from "../assets/img/about_agency/charlesdeluvio-qnQc2iMpacU-unsplash.jpg"
import Agency2 from "../assets/img/about_agency/tomas-malik-CMkJ-oT4lsQ-unsplash.jpg"
import { Trans, useTranslation } from "react-i18next";



function SliderAboutAgency() {

  const { t } = useTranslation()



    return (
        <section className="dark">
            <div className="wrapper">
                <div className="main-content">
                    <section className="agency section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="imgsec">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <div className="imgone big-bord wow fadeInDown" data-wow-delay=".8s">
                                                        <img className="thumparallax-down" src={Agency} alt="" />
                                                    </div>
                                                    <div className="exp">
                                                        <h2 className="nmb-font">28</h2>
                                                        <h6>{t('Slider-About-Agency.nmb')}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <div className="imgtwo big-bord wow fadeInUp" data-wow-delay=".6s">
                                                        <img className="thumparallax" src={Agency2} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-5 valign">
                                    <div className="content">
                                        <h6 className="sub-title wow" data-splitting>{t('Slider-About-Agency.sub-title')}</h6>
                                        <h4 className="wow" data-splittin style={{ color: 'white' }}>{t('Slider-About-Agency.wow')} <br />{t('Slider-About-Agency.wow2')}</h4>
                                        <p className="wow txt" data-splitting>{t('Slider-About-Agency.wow-txt')} <br />{t('Slider-About-Agency.wow-txt2')} </p>

                                        <a href="about-dark.html" className="btn-curve mt-40 wow fadeInUp" data-wow-delay=".8s"><span style={{ color: 'white' }}>{t('Slider-About-Agency.button')}</span></a><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default SliderAboutAgency;
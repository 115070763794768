import React from "react";
import i18n from '../assets/js/i18n'
import { useTranslation } from "react-i18next";

function CallToAction() {

    const { t } = useTranslation();

    return (
        <section className="dark">
            <div className="wrapper">
                <section className="call-action section-padding" data-overlay-dark="0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content text-center">
                                    <h6 className="wow" data-splitting>{t('Call-To-Action.title')}</h6>
                                    <h2 className="wow" data-splitting>{t('Call-To-Action.subtitle')}<b>{t('Call-To-Action.subtitle2')}<br />{t('Call-To-Action.subtitle3')}</b>.</h2>
                                    <a href="contact-dark.html" className="btn-curve btn-lit mt-30"><span>{t('Call-To-Action.span')}</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}


export default CallToAction;